<template>
  <b-row class="h-100">
    <b-col ref="mapContainer" sm="12" md="8" class="map-container p-0">
      <div v-if="searchThisAreaBtn" class="search-btn-container">
        <b-button
          class="border-secondary"
          variant="light"
          @click="searchThisArea"
        >
          Search this area
          <b-icon icon="search" size="sm" />
        </b-button>
      </div>
      <GmapMap
        ref="mapRef"
        :center="center"
        :options="{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false,
          styles: [
            {
              featureType: 'poi',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'transit',
              stylers: [{ visibility: 'off' }],
            },
          ],
        }"
        :zoom="zoom"
        class="w-100; h-100"
        @dragend="onCenterChanged"
        @idle="onCenterChanged"
      >
        <GmapMarker
          v-for="(m, index) in markers"
          :key="index"
          :position="m.position"
          :icon="m.iconMap"
          @click="showMarkerPopup(index, true)"
        />
        <gmap-info-window
          :options="{
            maxWidth: 220,
            minWidth: 220,
            pixelOffset: { width: 0, height: -35 },
          }"
          :position="popup.position"
          :opened="showPopup"
          @closeclick="onPopupClose"
        >
          <PopupContent
            :popup="popup"
            :check-store-open="checkStoreOpen"
            :get-popup-time="getPopupTime"
            :get-today-hours="getTodayHours"
            :formatted-address="
              formattedAddress ||
              `${$parent.address?.lat},${$parent.address?.lng}`
            "
            :utm="utm"
          />
        </gmap-info-window>
      </GmapMap>
    </b-col>
    <b-col
      ref="infoContainer"
      sm="12"
      md="4"
      class="p-2 p-md-3 d-none d-md-block info-container"
    >
      <MapControls
        :formatted-address="formattedAddress"
        :on-radius-change="onRadiusChange"
        :on-toggle="onToggle"
        :radius="radius"
        :store-brands="storeBrands"
        :selected-brands="selectedBrands"
        @geolocate="$emit('geolocate', $event)"
        @setPlace="$emit('setPlace', $event)"
        @brandsChange="onSelectedBrandsChange"
      />
      <StoreList
        ref="storeList"
        :stores-to-display="storesToDisplay"
        :popup="popup"
        :show-marker-popup="showMarkerPopup"
        :get-icon-u-r-l="getIconURL"
        :check-store-open="checkStoreOpen"
        :get-distance-km="getDistanceKm"
        :get-today-hours="getTodayHours"
        :format-day-hours="formatDayHours"
        :get-week-hours="getWeekHours"
        :get-today-week-day="getTodayWeekDay"
        :utm="utm"
      />
    </b-col>
    <b-button
      v-b-toggle.sidebar
      class="btn btn-primary position-absolute sidebar-button d-md-none"
      variant="dark"
      ><b-icon class="mt-1" icon="list" style="width: 30px; height: 30px"
    /></b-button>
    <b-sidebar
      id="sidebar"
      class="px-2 d-sm-none"
      title="Find a location"
      backdrop
    >
      <template #default="{ hide }">
        <MapControls
          :formatted-address="formattedAddress"
          :on-radius-change="onRadiusChange"
          :on-toggle="onToggle"
          :radius="radius"
          :store-brands="storeBrands"
          :selected-brands="selectedBrands"
          :show-header="false"
          :hide-toggle="hide"
          @geolocate="$emit('geolocate', $event)"
          @setPlace="$emit('setPlace', $event)"
          @brandsChange="onSelectedBrandsChange"
        />
        <StoreList
          ref="storeList-sidebar"
          :stores-to-display="storesToDisplay"
          :popup="popup"
          :show-marker-popup="showMarkerPopup"
          :get-icon-u-r-l="getIconURL"
          :check-store-open="checkStoreOpen"
          :get-distance-km="getDistanceKm"
          :get-today-hours="getTodayHours"
          :format-day-hours="formatDayHours"
          :get-week-hours="getWeekHours"
          :get-today-week-day="getTodayWeekDay"
          :hide-toggle="hide"
          :utm="utm"
        />
      </template>
    </b-sidebar>
  </b-row>
</template>

<script>
import StoreList from "@/components/StoreList.vue";
import MapControls from "@/components/MapControls.vue";
import PopupContent from "@/components/PopupContent.vue";
import {
  getDistance,
  formatTimeShow,
  formatDate,
  getWeekStartDate,
} from "@/utils.js";
import { BRAND_FOLDERS, NYF, LANDING, FRESH } from "@/constants";

export default {
  name: "GoogleMap",
  components: { StoreList, MapControls, PopupContent },
  props: {
    stores: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Object,
      default: () => ({}),
    },
    address: {
      type: Object,
      default: () => ({}),
    },
    zoom: {
      type: Number,
      default: 13,
    },
    radius: {
      type: Number,
      default: 5,
    },
    formattedAddress: {
      type: String,
      default: "",
    },
    addressID: {
      type: String,
      default: "",
    },
    coordinatesAccuracy: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentStore: {},
      popup: {},
      showPopup: false,
      searchThisAreaBtn: false,
      thisAreaSearchPending: false,
      radiusSearchPending: false,
      fittingBounds: false,
      selectedBrands: [],
      utm: "?utm_source=UDC-marketing&utm_medium=website&utm_campaign=UDC-Gift_Card_Store_Locator-202301",
    };
  },
  computed: {
    markers() {
      const markers = this.storesToDisplay.map((s) => ({
        position: {
          lat: s.yextDisplayCoordinate?.latitude,
          lng: s.yextDisplayCoordinate?.longitude,
        },
        name: s.name,
        icon: this.getIconURL(s.name),
        iconMap: this.getIconURL(s.name, true),
        directions: this.generateDirectionsLink(s),
      }));
      markers.push({
        position: {
          lat: this.address.lat,
          lng: this.address.lng,
        },
        name: "current_position",
      });
      return markers;
    },
    storesToDisplay() {
      if (this.loading) return [];
      return [...this.stores]
        .filter(
          (s) =>
            this.selectedBrands.some(
              (b) => b.toLowerCase() === s.name.toLowerCase()
            ) ||
            (s.name.toLowerCase().includes(NYF.toLowerCase()) &&
              this.selectedBrands.some(
                (b) => b.toLowerCase() === NYF.toLowerCase()
              )) ||
            (s.name.toLowerCase().includes(LANDING.toLowerCase()) &&
              this.selectedBrands.some(
                (b) => b.toLowerCase() === LANDING.toLowerCase()
              )) ||
            (s.name.toLowerCase().includes(FRESH.toLowerCase()) &&
              this.selectedBrands.some(
                (b) => b.toLowerCase() === FRESH.toLowerCase()
              ))
        )
        .map((s) => ({
          ...s,
          directions: this.generateDirectionsLink(s),
          ...{
            distance: getDistance(this.center, {
              lat: s.yextDisplayCoordinate?.latitude,
              lng: s.yextDisplayCoordinate?.longitude,
            }),
          },
        }))
        .sort((a, b) => a.distance - b.distance);
    },
    storeBrands() {
      const brands = [];
      this.stores.forEach((s) => {
        const storeName = s.name.trim().toLowerCase();
        if (
          storeName.includes(NYF.toLowerCase()) &&
          brands.every((b) => b.toLowerCase() !== NYF.toLowerCase())
        ) {
          brands.push(NYF);
        } else if (
          storeName.includes(LANDING.toLowerCase()) &&
          brands.every((b) => b.toLowerCase() !== LANDING.toLowerCase())
        ) {
          brands.push(LANDING);
        } else if (
          storeName.includes(FRESH.toLowerCase()) &&
          brands.every((b) => b.toLowerCase() !== FRESH.toLowerCase())
        ) {
          brands.push(FRESH);
        } else if (
          !storeName.includes(NYF.toLowerCase()) &&
          !storeName.includes(LANDING.toLowerCase()) &&
          !storeName.includes(FRESH.toLowerCase()) &&
          !brands.some((b) => b.toLowerCase() === storeName)
        ) {
          brands.push(s.name.trim());
        }
      });
      return brands.sort();
    },
  },
  watch: {
    markers: {
      handler(val) {
        this.showPopup = false;
        this.searchThisAreaBtn = false;
        if (val.length > 1) {
          setTimeout(async () => {
            if (!window.google || this.thisAreaSearchPending) return;
            try {
              const bounds = new window.google.maps.LatLngBounds();
              for (let i = 0; i < this.markers.length - 1; i++) {
                bounds.extend(this.markers[i].position);
              }
              this.fittingBounds = true;
              await this.$refs.mapRef?.fitBounds(bounds);
            } catch (e) {
              // do nothing
            }
          }, 0);
        } else if (val.length === 1 && this.storeBrands.length === 0) {
          // nothing found case
          setTimeout(async () => {
            if (!window.google || this.thisAreaSearchPending) return;
            try {
              const bounds = new window.google.maps.LatLngBounds();
              bounds.extend(val[0]?.position);
              await this.$refs.mapRef?.fitBounds(bounds);
              await this.$refs.mapRef?.$mapPromise.then((map) => {
                map.setZoom(4);
              });
            } catch (e) {
              // do nothing
            }
          }, 0);
        }
        setTimeout(() => {
          this.thisAreaSearchPending = false;
          this.radiusSearchPending = false;
          this.fittingBounds = false;
        }, 2000);
      },
      immediate: true,
    },
    storeBrands() {
      this.selectedBrands = this.storeBrands;
    },
  },
  methods: {
    showMarkerPopup(index, scrollToCard) {
      this.showPopup = true;
      this.popup = {
        ...this.storesToDisplay[index],
        ...this.markers[index],
        ...{ index },
      };
      const prevActiveCard = (this.$refs["storeList"].$refs[
        `card-${this.currentStore.index}`
      ] || [])[0];
      if (prevActiveCard) {
        prevActiveCard.classList.remove("card-selected");
      }
      const activeCard = (this.$refs["storeList"].$refs[`card-${index}`] ||
        [])[0];
      if (activeCard) {
        if (scrollToCard) {
          this.$refs.infoContainer.scrollTop = activeCard.offsetTop - 5;
        }
        activeCard.classList.add("card-selected");
      }
      this.currentStore = { ...(this.storesToDisplay[index] || {}), index };
    },
    getIconURL(brand, mapIcon) {
      try {
        let folder = "";
        Object.keys(BRAND_FOLDERS).forEach((key) => {
          if (
            decodeURIComponent(brand).toLowerCase().includes(key.toLowerCase())
          ) {
            folder = BRAND_FOLDERS[key];
          }
        });
        return {
          // eslint-disable-next-line no-undef
          url: require(`../assets/${folder}/logo${mapIcon ? "_map" : ""}.svg`),
        };
      } catch (err) {
        return {
          // eslint-disable-next-line no-undef
          url: require(`../assets/default_logo/logo${
            mapIcon ? "_map" : ""
          }.svg`),
        };
      }
    },
    onCenterChanged() {
      if (
        !this.thisAreaSearchPending &&
        !this.radiusSearchPending &&
        !this.fittingBounds
      ) {
        this.$refs.mapRef.$mapPromise.then((map) => {
          this.searchThisAreaBtn = map.zoom >= 9;
        });
      }
    },
    searchThisArea() {
      this.$refs.mapRef.$mapPromise.then((map) => {
        const bounds = map.getBounds();
        const center = {
          lat: map.center.lat(),
          lng: map.center.lng(),
        };
        const sw = {
          lat: bounds.getSouthWest().lat(),
          lng: bounds.getSouthWest().lng(),
        };
        const distance = getDistance(center, sw);
        const mapHeight = parseInt(
          getComputedStyle(this.$refs.mapContainer).height
        );
        const mapWidth = parseInt(
          getComputedStyle(this.$refs.mapContainer).width
        );
        let mapHeightWidthRatio = mapWidth / mapHeight;
        mapHeightWidthRatio = mapHeightWidthRatio > 1 ? mapHeightWidthRatio : 1;
        let radius = Math.ceil(distance / mapHeightWidthRatio / 1000);
        radius = radius > 50 ? 50 : radius;
        this.$emit("change:search-area", {
          ...center,
          radius,
        });
        this.searchThisAreaBtn = false;
        this.thisAreaSearchPending = true;
      });
    },
    onRadiusChange(radius) {
      this.$emit("change:radius", radius);
      this.searchThisAreaBtn = false;
      this.radiusSearchPending = true;
    },
    getDistanceKm(distance = 0) {
      return `${(distance / 1000).toFixed(2)} km`;
    },
    getStoreLocalDate(store) {
      const timeZone =
        store?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
      const date = new Date();
      const y = date.toLocaleString("en-US", {
        year: "numeric",
        timeZone,
      });
      const m = date.toLocaleString("en-US", {
        month: "numeric",
        timeZone,
      });
      const d = date.toLocaleString("en-US", {
        day: "numeric",
        timeZone,
      });
      const h = date.toLocaleString("en-US", {
        hour: "numeric",
        hour12: false,
        timeZone,
      });
      const mi = date.toLocaleString("en-US", {
        minute: "numeric",
        timeZone,
      });
      date.setFullYear(+y);
      date.setMonth(+m - 1);
      date.setDate(+d);
      date.setHours(+h);
      date.setMinutes(+mi);
      return date;
    },
    getTodayWeekDay(store) {
      return this.getStoreLocalDate(store)
        .toLocaleString("en", { weekday: "long" })
        .toLowerCase();
    },
    getTodayHours(store) {
      const today = this.getTodayWeekDay(store);
      return (store?.hours || {})[today];
    },
    checkStoreOpen(store) {
      const hours = this.getTodayHours(store);
      if (hours?.isClosed || !hours?.openIntervals?.length) return false;
      const currentTime =
        this.getStoreLocalDate(store).getHours() +
          this.getStoreLocalDate(store).getMinutes() / 100 || 24;
      try {
        let { start, end } = hours.openIntervals[0];
        start = +start.split(":")[0] + +start.split(":")[1] / 100 || 0;
        end = +end.split(":")[0] + +end.split(":")[1] / 100 || 24;
        // store closes the next day
        if (start > end) {
          return start <= currentTime || currentTime < end;
        }
        return start <= currentTime && end > currentTime;
      } catch (e) {
        // do nothing
      }
      return false;
    },
    getWeekHours(store) {
      const hours = store?.hours || {};
      return [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ].map((d, idx) => {
        const day = `<span class="hours-day">${
          d.charAt(0).toUpperCase() + d.slice(1)
        }</span>`;
        const dayHours = hours[d] || {};
        if (dayHours.isClosed) return `${day} Closed`;
        if (!dayHours.openIntervals?.length) return day;
        const holidayHours = this.checkHolidayHours(hours, idx, store);
        return `${day} ${this.formatDayHours(
          holidayHours?.openIntervals[0] || dayHours.openIntervals[0]
        )}`;
      });
    },
    checkHolidayHours(hours, day, store) {
      const monday = getWeekStartDate(this.getStoreLocalDate(store));
      const dayDate = monday.setDate(monday.getDate() + day);
      const holidayHours = (hours.holidayHours || []).find(
        (h) => h.date === formatDate(dayDate) && h.openIntervals?.length
      );
      return holidayHours || null;
    },
    getPopupTime(popup) {
      const holidayHours = this.checkHolidayHours(
        popup.hours,
        this.getStoreLocalDate(popup).getDay()
      );
      return this.formatDayHours(
        holidayHours?.openIntervals[0] ||
          this.getTodayHours(popup)?.openIntervals[0]
      );
    },
    formatDayHours(hours) {
      return `${formatTimeShow(hours.start)} - ${formatTimeShow(hours.end)}`;
    },
    onToggle() {
      if (this.selectedBrands.length) {
        this.selectedBrands = [];
      } else {
        this.selectedBrands = this.storeBrands;
      }
    },
    onSelectedBrandsChange(e) {
      this.selectedBrands = e;
    },
    onPopupClose() {
      this.showPopup = false;
      this.$nextTick(() => {
        this.popup = {};
      });
      const prevActiveCard = (this.$refs["storeList"].$refs[
        `card-${this.currentStore.index}`
      ] || [])[0];
      if (prevActiveCard) {
        prevActiveCard.classList.remove("card-selected");
      }
    },
    generateDirectionsLink(store) {
      let origin = `&origin=${
        encodeURIComponent(this.formattedAddress) ||
        `${this.address?.lat},${this.address.lng}`
      }`;
      if (this.addressID) {
        origin += `&origin_place_id=${this.addressID}`;
      }
      // don't include starting point when the search is done by auto detected user coordinates
      if (this.coordinatesAccuracy > 0) {
        origin = "";
      }
      let destination = `&destination=${store.yextDisplayCoordinate?.latitude},${store.yextDisplayCoordinate?.longitude}`;
      if (store.googlePlaceId) {
        destination += `&destination_place_id=${store.googlePlaceId}`;
      }
      return `https://www.google.com/maps/dir/?api=1${origin}${destination}`;
    },
  },
};
</script>

<style>
.map-container {
  position: relative;
}
.dropdown-menu {
  font-size: 15px !important;
}
.radius-dropdown .dropdown-menu {
  --bs-dropdown-min-width: 5rem !important;
}
.brands-dropdown .dropdown-menu {
  --bs-dropdown-min-width: 17rem !important;
}
.brand-option label {
  margin-left: 5px;
  cursor: pointer;
}
.card-title {
  font-size: 16px;
  font-weight: 600;
}
.search-btn-container {
  position: absolute;
  top: 10px;
  left: 0;
  right: 140px;
  transform: translate(50%, 0);
  z-index: 1;
}
.search-btn-container button {
  font-size: 15px !important;
}
.search-btn-container svg {
  font-size: 14px !important;
  position: relative;
  top: -1px;
  left: 3px;
}
.info-container {
  overflow: auto;
}
.hours-day {
  display: inline-block;
  min-width: 70px;
}
.sidebar-button {
  left: 0 !important;
  bottom: 30px !important;
  height: 70px !important;
  width: 70px !important;
  border-radius: 100% !important;
}
#sidebar {
  padding: 5px 10px;
  z-index: 2;
}
.b-sidebar-body {
  overflow-x: hidden;
}
#sidebar .close {
  border: none !important;
  background: transparent !important;
}
.b-sidebar-header {
  padding: 0.5rem 0 !important;
}
.pac-container {
  z-index: 3000;
}
@media only screen and (min-width: 768px) {
  .map-container,
  .info-container {
    height: 100% !important;
  }
}
</style>
