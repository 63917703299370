var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"cards-container"},[_c('div',{staticClass:"mb-2 mt-2"},[_vm._v("Results found: "+_vm._s(_vm.storesToDisplay.length))]),_vm._l((_vm.storesToDisplay),function(store,index){return _c('b-card',{key:index,ref:`card-${index}`,refInFor:true,staticClass:"mb-2",attrs:{"role":"button"},on:{"click":() => {
          _vm.hideToggle();
          _vm.showMarkerPopup(index);
        }}},[_c('h5',{staticClass:"fw-bold",staticStyle:{"font-size":"17px"}},[_c('img',{staticStyle:{"width":"50px","height":"50px","margin-right":"10px","margin-bottom":"5px"},attrs:{"src":_vm.getIconURL(store.name)?.url,"alt":store.name}}),_c('span',{staticClass:"d-inline-block",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(store.name))])]),_c('hr',{staticClass:"mt-2",staticStyle:{"color":"#b8b8b8"}}),(store.address)?_c('b-card-text',{staticStyle:{"font-size":"14px"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"mb-2"},[(store.yextDisplayCoordinate)?_c('b-button',{staticClass:"mb-1 px-1 py-0 mr-2 d-inline-block",staticStyle:{"font-size":"12px","margin-right":"10px"},attrs:{"variant":"outline-dark","size":"sm"}},[_vm._v(" "+_vm._s(_vm.getDistanceKm(store.distance))+" ")]):_vm._e(),_c('b-dropdown',{staticClass:"d-inline-block ml-2 mb-1",staticStyle:{"font-size":"12px","margin-right":"10px"},attrs:{"size":"sm","variant":!_vm.checkStoreOpen(store)
                    ? 'outline-danger'
                    : 'outline-success',"toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(!_vm.checkStoreOpen(store) ? `Closed${ _vm.getTodayHours(store)?.openIntervals?.length ? " now:" : "" }` : "Open now:")+" "+_vm._s(_vm.getTodayHours(store)?.openIntervals?.length ? _vm.formatDayHours( _vm.getTodayHours(store)?.openIntervals[0] ) : "")+" ")])]},proxy:true}],null,true)},_vm._l((_vm.getWeekHours(store)),function(i,idx){return _c('b-dropdown-item',{key:idx},[_c('span',{staticStyle:{"font-size":"12px"},style:({
                      'font-weight': i
                        .toLowerCase()
                        .includes(_vm.getTodayWeekDay(store))
                        ? 'bold'
                        : 'normal',
                    }),domProps:{"innerHTML":_vm._s(i)}})])}),1),(_vm.showCardTypesAccepted)?[(store.c_udcaccepted)?_c('b-button',{staticClass:"d-inline-block px-1 py-0 mb-1",staticStyle:{"font-size":"12px","margin-right":"10px"},attrs:{"variant":"outline-secondary","size":"sm"}},[_vm._v(" Ultimate Dining Card ")]):_vm._e(),(store.c_diningcardaccepted)?_c('b-button',{staticClass:"d-inline-block px-1 py-0 mb-1",staticStyle:{"font-size":"12px"},attrs:{"variant":"outline-secondary","size":"sm"}},[_vm._v(" Discount card ")]):_vm._e()]:_vm._e()],2),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(store.address.line1)+" ")]),(store.address.line2)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(store.address.line2)+" ")]):_vm._e(),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(store.address.city)+" "+_vm._s(store.address.region)+" "+_vm._s(store.address.postalCode)+" ")]),(store.mainPhone)?_c('b-link',{attrs:{"href":`tel:${store.mainPhone}`}},[_vm._v(" "+_vm._s(store.mainPhone)+" ")]):_vm._e()],1),(store.c_pagesURL || store.directions)?_c('b-col',{staticClass:"text-center text-xl-end mt-xl-auto mb-xl-auto mt-3",attrs:{"sm":"12"}},[(store.c_pagesURL)?_c('b-button',{staticClass:"link-button",staticStyle:{"margin-right":"12px"},attrs:{"variant":"outline-dark","title":"Visit website","size":"sm"},on:{"click":function($event){return _vm.openPage(`${store.c_pagesURL}${_vm.utm}`)}}},[_vm._v(" Web Page ")]):_vm._e(),(store.directions)?_c('b-button',{staticClass:"link-button",attrs:{"variant":"outline-dark","title":"Get directions","size":"sm"},on:{"click":function($event){return _vm.openPage(`${store.directions}`)}}},[_vm._v(" Directions ")]):_vm._e()],1):_vm._e()],1)],1):_vm._e()],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }