<template>
  <div>
    <div v-if="popup.name !== 'current_position'">
      <div style="text-align: center">
        <img
          :src="popup.icon?.url || ''"
          :alt="popup.name"
          style="height: 50px; width: 50px"
        />
      </div>
      <div style="text-align: center; margin-top: 5px">
        <h5>{{ popup.name }}</h5>
      </div>
      <div
        :style="{
          marginBottom: '5px',
          color: !checkStoreOpen(popup) ? '#dc3545' : '#198754',
        }"
      >
        {{
          !checkStoreOpen(popup)
            ? `Closed${
                getTodayHours(popup)?.openIntervals?.length
                  ? " now: " + getPopupTime(popup)
                  : ""
              }`
            : `Open now:
              ${
                getTodayHours(popup)?.openIntervals?.length
                  ? getPopupTime(popup)
                  : ""
              }`
        }}
      </div>
      <div>
        {{ popup.address?.line1 || "" }}
      </div>
      <div>
        {{ popup.address?.line2 || "" }}
      </div>
      <div>
        {{
          `${popup.address?.city || ""} ${popup.address?.region || ""} ${
            popup.address?.postalCode || ""
          }`
        }}
      </div>
      <div style="margin-top: 5px">
        <a v-if="popup.mainPhone" :href="`tel:${popup.mainPhone}`">{{
          popup.mainPhone
        }}</a>
      </div>
      <div
        :style="{
          textAlign: 'center',
          marginTop: '10px',
          marginLeft: '5px',
          display: !popup.c_pagesURL && !popup.directions ? 'none' : '',
        }"
      >
        <a
          v-if="popup.c_pagesURL"
          :href="`${popup.c_pagesURL}${utm}`"
          target="_blank"
          title="Visit website"
          class="d-inline-block"
          style="margin-right: 10px"
        >
          <button class="btn btn-outline-dark btn-sm px-2 py-1 mt-1">
            Web Page
          </button>
        </a>
        <a
          v-if="popup.directions"
          :href="popup.directions"
          target="_blank"
          title="Get directions"
        >
          <button class="btn btn-outline-dark btn-sm px-2 py-1 mt-1">
            Directions
          </button>
        </a>
      </div>
    </div>
    <div v-else>Search Location:<br />{{ formattedAddress }}</div>
  </div>
</template>

<script>
export default {
  name: "PopupContent",
  props: {
    popup: {
      type: Object,
      default: () => ({}),
    },
    checkStoreOpen: {
      type: Function,
      default: () => ({}),
    },
    getPopupTime: {
      type: Function,
      default: () => ({}),
    },
    getTodayHours: {
      type: Function,
      default: () => ({}),
    },
    formattedAddress: {
      type: String,
      default: "",
    },
    utm: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
a {
  outline: none;
}
</style>
