<template>
  <div id="app">
    <div class="h-100 w-100 container-fluid">
      <div
        v-if="loading"
        class="h-100 w-100 d-flex justify-content-center align-items-center"
      >
        <b-spinner type="grow" variatn="dark" />
      </div>
      <div v-show="!loading">
        <div class="header">
          <img
            src="./assets/udc_logo.png"
            alt="UDC logo"
            @click="goToUDCPage"
          />
        </div>
        <div class="content-container">
          <GoogleMap
            :address="address"
            :stores="stores"
            :zoom="zoom"
            :center="center"
            :radius="radius"
            :formatted-address="formattedAddress"
            :address-i-d="addressID"
            :coordinates-accuracy="coordinatesAccuracy"
            :loading="loading"
            @located="getRestaurants"
            @geolocate="geolocate"
            @setPlace="setPlace"
            @change:center="center = $event"
            @change:radius="
              (e) => {
                radius = e;
                getRestaurants(center);
              }
            "
            @change:search-area="getRestaurants($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const defaultCenter = { lat: 54.121, lng: -102.055 };
import GoogleMap from "./components/GoogleMap.vue";
import { yext } from "@/config.js";

export default {
  name: "App",
  components: {
    GoogleMap,
  },
  data() {
    return {
      stores: [],
      center: defaultCenter,
      loading: true,
      zoom: 4,
      radius: 5,
      limit: 50,
      address: {},
      formattedAddress: "",
      addressID: "",
      coordinatesAccuracy: 0,
    };
  },
  mounted() {
    this.geolocate();
  },
  methods: {
    async getRestaurants(location) {
      this.loading = true;
      this.stores = [];
      const locationStr = `${location.lat},${location.lng}`;
      let cardTypeFilter = '{"c_udcaccepted":{"$eq":true}}'; // udc card mode is default
      let cardTypeFields = "c_udcaccepted";
      const urlParams = new URLSearchParams(window.location.search);
      const mode = urlParams.get("mode");
      if (mode === "dining") {
        // only dining card (discount) mode
        cardTypeFilter = '{"c_diningcardaccepted":{"$eq":true}}';
        cardTypeFields = "c_diningcardaccepted";
      } else if (mode === "all") {
        // both udc and dining card (discount) mode
        cardTypeFilter =
          '{$or: [{"c_udcaccepted":{"$eq":true}}, {"c_diningcardaccepted":{"$eq":true}}]}';
        cardTypeFields = "c_udcaccepted,c_diningcardaccepted";
      }
      const fields =
        "c_pagesURL,address,name,yextDisplayCoordinate,logo,name,mainPhone,hours,timezone,googlePlaceId," +
        cardTypeFields;
      const url = `${yext.endpoint}?api_key=${yext.apiKey}&v=${
        yext.v
      }&entityTypes=${yext.entityTypes}&limit=${this.limit}&radius=${
        location.radius || this.radius
      }&location=${locationStr}&fields=${fields}&filter={"$and": [${cardTypeFilter}, {"closed":{"$eq":false}}]}`;
      const resp = await this.fetchRestaurantBatch(url);
      if (resp.count > this.limit) {
        for (let i = this.limit; i < resp.count; i += this.limit) {
          await this.fetchRestaurantBatch(`${url}&offset=${i}`);
        }
      }
      this.loading = false;
    },
    async fetchRestaurantBatch(url) {
      const resp = await fetch(url)
        .then((r) => r.json())
        .catch((err) => {
          console.log(err);
        });
      this.stores.push(...(resp?.response?.entities || []));
      return resp.response || {};
    },
    async geolocate(manualLocate) {
      this.loading = true;
      const permissions = await navigator?.permissions?.query({
        name: "geolocation",
      });
      if (permissions?.state !== "granted") {
        this.loading = false;
      }
      if (permissions?.state === "denied" && manualLocate) {
        alert("Sorry, we are unable to determine your location at this time");
        return;
      }
      await navigator?.geolocation?.getCurrentPosition((position) => {
        this.center = defaultCenter;
        this.$nextTick(async () => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.coordinatesAccuracy = position.coords.accuracy;
          this.address = this.center;
          this.addressID = "";
          this.formattedAddress = "";
          // await this.getAddressByCoordinates(this.center.lat, this.center.lng);
          await this.getRestaurants(this.center);
        });
      });
    },
    setPlace(place) {
      this.loading = true;
      this.center = defaultCenter;
      this.addressID = place?.place_id || "";
      this.formattedAddress = place?.formatted_address || "";
      this.coordinatesAccuracy = 0;
      this.$nextTick(async () => {
        this.center = {
          lat: place?.geometry?.location?.lat(),
          lng: place?.geometry?.location?.lng(),
        };
        this.address = this.center;
        await this.getRestaurants(this.center);
      });
    },
    goToUDCPage() {
      window.location.replace("https://theultimatediningcard.ca/udc/");
    },
    async getAddressByCoordinates(lat, lng) {
      if (!window.google) return "";
      const self = this;
      const geocoder = new window.google.maps.Geocoder();
      var latlng = new window.google.maps.LatLng(lat, lng);
      geocoder.geocode(
        {
          latLng: latlng,
        },
        (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            if (results[1]) {
              self.addressID = results[1].place_id || "";
            }
          }
        }
      );
    },
  },
};
</script>

<style>
#app {
  height: 100vh;
  font-family: "Open Sans", Arial, sans-serif;
}
.header {
  background-color: black;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  height: 90px;
}
.header img {
  max-width: 150px;
  object-fit: contain;
  cursor: pointer;
}
.content-container {
  height: calc(100vh - 90px);
  position: relative;
  top: 90px;
}
@media only screen and (max-width: 767px) {
  .header {
    text-align: center;
    height: 70px;
  }
  .header img {
    max-width: 100px;
  }
  .content-container {
    height: calc(100vh - 70px);
    top: 70px;
  }
}
</style>
