var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.popup.name !== 'current_position')?_c('div',[_c('div',{staticStyle:{"text-align":"center"}},[_c('img',{staticStyle:{"height":"50px","width":"50px"},attrs:{"src":_vm.popup.icon?.url || '',"alt":_vm.popup.name}})]),_c('div',{staticStyle:{"text-align":"center","margin-top":"5px"}},[_c('h5',[_vm._v(_vm._s(_vm.popup.name))])]),_c('div',{style:({
        marginBottom: '5px',
        color: !_vm.checkStoreOpen(_vm.popup) ? '#dc3545' : '#198754',
      })},[_vm._v(" "+_vm._s(!_vm.checkStoreOpen(_vm.popup) ? `Closed${ _vm.getTodayHours(_vm.popup)?.openIntervals?.length ? " now: " + _vm.getPopupTime(_vm.popup) : "" }` : `Open now: ${ _vm.getTodayHours(_vm.popup)?.openIntervals?.length ? _vm.getPopupTime(_vm.popup) : "" }`)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.popup.address?.line1 || "")+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.popup.address?.line2 || "")+" ")]),_c('div',[_vm._v(" "+_vm._s(`${_vm.popup.address?.city || ""} ${_vm.popup.address?.region || ""} ${ _vm.popup.address?.postalCode || "" }`)+" ")]),_c('div',{staticStyle:{"margin-top":"5px"}},[(_vm.popup.mainPhone)?_c('a',{attrs:{"href":`tel:${_vm.popup.mainPhone}`}},[_vm._v(_vm._s(_vm.popup.mainPhone))]):_vm._e()]),_c('div',{style:({
        textAlign: 'center',
        marginTop: '10px',
        marginLeft: '5px',
        display: !_vm.popup.c_pagesURL && !_vm.popup.directions ? 'none' : '',
      })},[(_vm.popup.c_pagesURL)?_c('a',{staticClass:"d-inline-block",staticStyle:{"margin-right":"10px"},attrs:{"href":`${_vm.popup.c_pagesURL}${_vm.utm}`,"target":"_blank","title":"Visit website"}},[_c('button',{staticClass:"btn btn-outline-dark btn-sm px-2 py-1 mt-1"},[_vm._v(" Web Page ")])]):_vm._e(),(_vm.popup.directions)?_c('a',{attrs:{"href":_vm.popup.directions,"target":"_blank","title":"Get directions"}},[_c('button',{staticClass:"btn btn-outline-dark btn-sm px-2 py-1 mt-1"},[_vm._v(" Directions ")])]):_vm._e()])]):_c('div',[_vm._v("Search Location:"),_c('br'),_vm._v(_vm._s(_vm.formattedAddress))])])
}
var staticRenderFns = []

export { render, staticRenderFns }