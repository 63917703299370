export const BRAND_FOLDERS = {
  // eslint-disable-next-line
  "añejo": "añejo_restaurant",
  "bier markt": "bier_markt",
  "blanco cantina": "blanco_cantina",
  "east side mario": "east_side_mario's",
  "elephant & castle": "elephant_&_castle",
  fresh: "fresh",
  harvey: "harvey's",
  kelseys: "kelseys_original_roadhouse",
  landing: "landing",
  montana: "montana's",
  "new york fries": "new_york_fries",
  "original joe": "original_joe's",
  "pickle barrel": "pickle_barrel",
  "state & main": "state_&_main",
  "swiss chalet": "swiss_chalet",
  priest: "the_burger's_priest",
};

// restaurant names which have modifiers (for example, Fresh on Spadina or Kelly's Landing)
export const NYF = "New York Fries";
export const LANDING = "Landing";
export const FRESH = "Fresh";
