<template>
  <b-row class="map-controls" style="width: 100%">
    <b-col v-if="showHeader" cols="12" class="text-center pb-2">
      <h4 class="mb-0 mb-md-2">Find a Location</h4>
    </b-col>
    <b-col cols="12" class="text-left pb-2 mb-2">
      <small
        >Have an Ultimate Dining Card and looking for a location? Fill in your
        details below for a list of restaurants near you. Use your Ultimate
        Dining Card at some of Canada’s most iconic restaurants.</small
      >
    </b-col>
    <b-col cols="9" class="pl-xs-0 pl-sm-5 pb-2 d-flex w-100">
      <AddressSearch
        :formatted-address="formattedAddress"
        @geolocate="
          (e) => {
            $emit('geolocate', e);
            hideToggle();
          }
        "
        @setPlace="
          (e) => {
            $emit('setPlace', e);
            hideToggle();
          }
        "
      />
    </b-col>
    <b-col>
      <b-dropdown
        size="sm"
        :text="`${radius} km`"
        variant="outline-dark"
        data-offset="10,20"
        class="radius-dropdown"
      >
        <b-dropdown-item-button
          v-for="i in [5, 10, 20]"
          :key="i"
          @click="
            () => {
              onRadiusChange(i);
              hideToggle();
            }
          "
        >
          {{ i }} km
        </b-dropdown-item-button>
      </b-dropdown>
      <b-dropdown
        v-if="storeBrands.length > 1"
        size="sm"
        text="Brands"
        variant="outline-dark"
        class="mx-2 brands-dropdown"
      >
        <b-dropdown-form>
          <b-form-checkbox-group v-model="selected">
            <b-form-checkbox
              v-for="brand in storeBrands"
              :key="brand"
              :value="brand"
              class="mb-2 brand-option"
              >{{ brand }}</b-form-checkbox
            >
            <b-dropdown-divider></b-dropdown-divider>
            <div style="text-align: center">
              <b-button variant="outline-dark" size="sm" @click="onToggle">
                Toggle all
              </b-button>
            </div>
          </b-form-checkbox-group>
        </b-dropdown-form>
      </b-dropdown>
    </b-col>
  </b-row>
</template>

<script>
import AddressSearch from "./AddressSearch.vue";
export default {
  name: "MapControls",
  components: { AddressSearch },
  props: {
    formattedAddress: {
      type: String,
      default: "",
    },
    onRadiusChange: {
      type: Function,
      default: () => ({}),
    },
    onToggle: {
      type: Function,
      default: () => ({}),
    },
    radius: {
      type: Number,
      default: 5,
    },
    storeBrands: {
      type: Array,
      default: () => [],
    },
    selectedBrands: {
      type: Array,
      default: () => [],
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    hideToggle: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      selected: this.selectedBrands,
    };
  },
  watch: {
    selected(val) {
      this.$emit("brandsChange", val);
    },
    selectedBrands(val) {
      this.selected = val;
    },
  },
};
</script>

<style>
.map-controls {
}
input[type="checkbox"] {
  accent-color: black;
}
.dropdown-item:active {
  background-color: black !important;
}
@media only screen and (max-width: 575px) {
  .map-controls {
    max-width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .map-controls div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
