<template>
  <b-row>
    <b-col class="cards-container">
      <div class="mb-2 mt-2">Results found: {{ storesToDisplay.length }}</div>
      <b-card
        v-for="(store, index) in storesToDisplay"
        :ref="`card-${index}`"
        :key="index"
        class="mb-2"
        role="button"
        @click="
          () => {
            hideToggle();
            showMarkerPopup(index);
          }
        "
      >
        <h5 class="fw-bold" style="font-size: 17px">
          <img
            style="
              width: 50px;
              height: 50px;
              margin-right: 10px;
              margin-bottom: 5px;
            "
            :src="getIconURL(store.name)?.url"
            :alt="store.name"
          />
          <span class="d-inline-block" style="margin-right: 10px">{{
            store.name
          }}</span>
        </h5>
        <hr class="mt-2" style="color: #b8b8b8" />
        <b-card-text v-if="store.address" style="font-size: 14px">
          <b-row>
            <b-col sm="12">
              <div class="mb-2">
                <b-button
                  v-if="store.yextDisplayCoordinate"
                  class="mb-1 px-1 py-0 mr-2 d-inline-block"
                  variant="outline-dark"
                  size="sm"
                  style="font-size: 12px; margin-right: 10px"
                >
                  {{ getDistanceKm(store.distance) }}
                </b-button>
                <b-dropdown
                  size="sm"
                  :variant="
                    !checkStoreOpen(store)
                      ? 'outline-danger'
                      : 'outline-success'
                  "
                  class="d-inline-block ml-2 mb-1"
                  toggle-class="text-decoration-none"
                  style="font-size: 12px; margin-right: 10px"
                  no-caret
                >
                  <template #button-content>
                    <div>
                      {{
                        !checkStoreOpen(store)
                          ? `Closed${
                              getTodayHours(store)?.openIntervals?.length
                                ? " now:"
                                : ""
                            }`
                          : "Open now:"
                      }}
                      {{
                        getTodayHours(store)?.openIntervals?.length
                          ? formatDayHours(
                              getTodayHours(store)?.openIntervals[0]
                            )
                          : ""
                      }}
                    </div>
                  </template>
                  <b-dropdown-item
                    v-for="(i, idx) of getWeekHours(store)"
                    :key="idx"
                    ><span
                      style="font-size: 12px"
                      :style="{
                        'font-weight': i
                          .toLowerCase()
                          .includes(getTodayWeekDay(store))
                          ? 'bold'
                          : 'normal',
                      }"
                      v-html="i"
                    ></span
                  ></b-dropdown-item>
                </b-dropdown>
                <template v-if="showCardTypesAccepted">
                  <b-button
                    v-if="store.c_udcaccepted"
                    class="d-inline-block px-1 py-0 mb-1"
                    variant="outline-secondary"
                    size="sm"
                    style="font-size: 12px; margin-right: 10px"
                  >
                    Ultimate Dining Card
                  </b-button>
                  <b-button
                    v-if="store.c_diningcardaccepted"
                    class="d-inline-block px-1 py-0 mb-1"
                    variant="outline-secondary"
                    size="sm"
                    style="font-size: 12px"
                  >
                    Discount card
                  </b-button>
                </template>
              </div>
              <p class="mb-0">
                {{ store.address.line1 }}
              </p>
              <p v-if="store.address.line2" class="mb-0">
                {{ store.address.line2 }}
              </p>
              <p class="mb-0">
                {{ store.address.city }}
                {{ store.address.region }}
                {{ store.address.postalCode }}
              </p>
              <b-link v-if="store.mainPhone" :href="`tel:${store.mainPhone}`">
                {{ store.mainPhone }}
              </b-link>
            </b-col>
            <b-col
              v-if="store.c_pagesURL || store.directions"
              sm="12"
              class="text-center text-xl-end mt-xl-auto mb-xl-auto mt-3"
            >
              <b-button
                v-if="store.c_pagesURL"
                variant="outline-dark"
                title="Visit website"
                size="sm"
                class="link-button"
                style="margin-right: 12px"
                @click="openPage(`${store.c_pagesURL}${utm}`)"
              >
                Web Page
              </b-button>
              <b-button
                v-if="store.directions"
                variant="outline-dark"
                title="Get directions"
                size="sm"
                class="link-button"
                @click="openPage(`${store.directions}`)"
              >
                Directions
              </b-button>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "StoreList",
  props: {
    storesToDisplay: {
      type: Array,
      default: () => [],
    },
    popup: {
      type: Object,
      default: () => ({}),
    },
    showMarkerPopup: {
      type: Function,
      default: () => ({}),
    },
    getIconURL: {
      type: Function,
      default: () => ({}),
    },
    checkStoreOpen: {
      type: Function,
      default: () => false,
    },
    getDistanceKm: {
      type: Function,
      default: () => 0,
    },
    getTodayHours: {
      type: Function,
      default: () => ({}),
    },
    formatDayHours: {
      type: Function,
      default: () => ({}),
    },
    getWeekHours: {
      type: Function,
      default: () => [],
    },
    getTodayWeekDay: {
      type: Function,
      default: () => "",
    },
    hideToggle: {
      type: Function,
      default: () => ({}),
    },
    utm: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showCardTypesAccepted: false,
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.showCardTypesAccepted = ["all", "dining"].includes(
      urlParams.get("mode")
    );
  },
  methods: {
    openPage(url) {
      window.open(url);
    },
  },
};
</script>

<style>
.card-selected {
  border-color: transparent !important;
  box-shadow: 0 0 7px #56554f !important;
}
.cards-container .dropdown-toggle {
  font-size: 12px !important;
  padding: 0 4px !important;
}
.cards-container .dropdown-menu .dropdown-item:active,
.cards-container .dropdown-menu .dropdown-item:hover {
  background-color: transparent !important;
  color: initial !important;
}
@media only screen and (min-width: 768px) and (max-width: 890px) {
  .brands-dropdown .dropdown-menu {
    --bs-dropdown-min-width: 15rem !important;
  }
}
@media only screen and (max-width: 575px) {
  .card-selected {
    border-color: #56554f !important;
    box-shadow: none !important;
  }
}
</style>
