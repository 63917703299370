<template>
  <div class="w-100">
    <GmapAutocomplete
      :value="formattedAddress"
      :options="autocompleteOptions"
      :select-first-on-enter="true"
      class="gmap-autocomplete d-inline-block px-2"
      @place_changed="$emit('setPlace', $event)"
    />

    <b-button
      title="Locate me"
      class="locator-btn"
      variant="dark"
      @click="$emit('geolocate', true)"
    >
      <img src="../assets/locator.svg" alt="Locate me" />
    </b-button>
  </div>
</template>

<script>
export default {
  name: "AddressSearch",
  props: {
    formattedAddress: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      autocompleteOptions: {
        componentRestrictions: {
          country: ["ca"],
        },
      },
    };
  },
};
</script>

<style scoped>
.locator-btn {
  cursor: pointer;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  margin-left: 10px;
}
.locator-btn img {
  position: relative;
  left: -1px;
}
.gmap-autocomplete {
  width: calc(100% - 55px);
  height: 46px;
  border-radius: 7px;
}
@media only screen and (max-width: 575px) {
  .gmap-autocomplete {
    font-size: 14px;
    height: 35px;
  }
}
</style>
